header {
    width: 100vw;
    display: flex;
    justify-content: center;
    z-index: 10;
    background-color: #EEE;
}

.header--mobile {
    z-index: 10;
}

.header__content {
    display: flex;
    width: 100%;
    max-width: 1200px;
    justify-content: space-between;
}

.notation-selector {
    display: flex;
    align-items: center;
}

.hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 24px;
    height: 24px;
    cursor: pointer;
    overflow: hidden;
}

.line {
    width: 100%;
    height: 2px;
    background-color: #000;
    transition: all 0.3s ease;
}

.active {
    transform: translateY(11px) rotate(45deg);
}

.active:nth-child(2) {
    opacity: 0;
    transform: translateX(500%) rotate(-45deg);
}

.active:nth-child(3) {
    transform: translateY(-11px)  rotate(-45deg);
}

.collapsed-menu {
    width: 100vw;
    position: absolute;
    bottom: -259%;
    right: 0;
    transition: all 0.3s ease;
    z-index: 10;
}

.collapsed-menu--open {
    transform: translateX(0);
}

.collapsed-menu--closed {
    transform: translateX(100%);
}
.staff {
    height: 52px;
    position: relative;
}

@media only screen and (max-width: 600px) {
    .staff {
        scale: 0.8;
        width: 120%;
        transform: translateX(-10%);
    }
}

.staff__text {
    position: absolute;
    top: -40px;
}

.staff__lines {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-right: 2px solid #000;
    background-color: #EEE
}

.staff__line {
    height: 0;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    width: 100%;
    background-color: #000;
}

.staff__content {
    position: absolute;
    top: 1px;
    left: 0;
    width: 100%;
    height: 50px;
}

.staff__content__clef {
    position: absolute;
    top: -22px;
    height: 98px;
    margin-left: -9px;
}

.staff__content__clef__png {
    height: 100%;
}

.staff__content__signature {
    position: absolute;
    display: flex;
    gap: 9px;
    top: 0;
    left: 38px;
    height: 50px;
}

.staff__content__notes {
    position: absolute;
    display: flex;
    gap: 32px;
    top: 0;
    left: 113px;
    height: 50px;
    transition: all 0.3s ease;
}

.musical-character-container {
    position: relative;
    height: 100%;
    width: 100%;
}

.musical-character {
    position: absolute;
    height: 100%;
}

.musical-character img {
    position: absolute;
}

.musical-character__text {
    position: absolute;
    top: 70px;
}

.note {
    position: absolute;
}

.note__dot {
    position: absolute;
    background: #000;
    height: 11px;
    width: 16px;
    top: -5.5px;
    left: -0.5px;
    border-radius: 50%;
    transform: rotate(-22deg); /* This should rotate the entire element */
}

.note__line {
    position: absolute;
    top: -37px;
    right: -15.25px;
    background: #000;
    border-radius: 1.5px;
    height: 37px;
    width: 1.5px;
}

.note__accidental {
    position: absolute;
    right: 0px;
    width: 10px;
    top: 50px;
}

.note__line--reversed {
    top: 0;
    right: -1.5px;
}
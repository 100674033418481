.circle-of-fifth {
    width: 30em;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: calc(100vw - 40px);
    overflow: hidden;
}

.circle-of-fifth__circle{
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #000;
    max-width: calc(100vw - 40px);
    overflow: hidden;
    aspect-ratio: 1;
    border-radius: 50%;
}

.circle-outer {
    position: absolute;
    width: 100%;
    aspect-ratio: 1;
    border-radius: 50%; /* round its corners to turn the square into a disc */
    background:
            conic-gradient(from var(--start, -15deg) /* specify the angle to start from */,
            #DDD var(--angle, 30deg) /* specify the sector angle */,
            #0000 0% /* abruptly switch to transparent after */)
}
.circle-outer__content-container {
    position: absolute;
    width: 20%;
    top: 0;
    aspect-ratio: 1;
    //background: white;
    border-radius: 50%;
    z-index: 3;
    transform-origin: center 250%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

}

.circle-outer__content {
    width: 100%;
}

.circle-outer__content .staff {
    margin-left: -10px;
    width: 120%;
    scale: 0.5;
}



.circle-outer__content .staff:last-child {
    margin-top: -20px;
    margin-left: 5px;
}

@media (max-width: 475px) {
    .circle-outer__content .staff {
        scale: 0.3;
        width: 180%;
        margin-left: -20px;
    }

    .circle-outer__content .staff:last-child {
        margin-top: -25px;
        margin-left: -18px;
    }
}
.circle-inner {
    position: absolute;
    width: 70%;
    aspect-ratio: 1;
    border-radius: 50%; /* round its corners to turn the square into a disc */
    background:
            conic-gradient(from var(--start, -15deg) /* specify the angle to start from */,
            #222 var(--angle, 30deg) /* specify the sector angle */,
            #0000 0% /* abruptly switch to transparent after */)
}
.circle-inner__content-container {
    position: absolute;
    width: 12%;
    left: 44%;
    top: 20%;
    aspect-ratio: 1;
    //background: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform-origin: center 250%;
}
.circle-center {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35%;
    aspect-ratio: 1;
    border-radius: 50%;
    z-index: 2;
    border: 1px solid #000;
    position: relative;
    overflow: hidden; /* Ensures the blurred background doesn't spill */
}

.circle-center::before {
    content: "";
    position: absolute; /* Match the main background's fixed position */
    z-index: 3;


    display: block;
    background-image: url('./../../assets/images/main-background.jpeg');
    background-size: 600px;
    background-attachment: fixed; /* Matches the main background's behavior */
    width: 100vw;
    height: 100vh;

    filter: blur(9px) grayscale(0.7) contrast(0.1) brightness(1.8);
}

.circle-center__controls {
    position: absolute;
    z-index: 4;
    bottom: 0;
    width: 100%;
    overflow: visible;
}

.circle-center__content {
    position: relative;
    z-index: 3;
    background-color: #222;
    aspect-ratio: 1;
    border-radius: 50%;
    width: 15px;

}

.circle-center__hands {
    position: absolute;
    z-index: 3;
}

.circle-center__hand {
    position: absolute;
    left: -2px;
    bottom: 100%;
    height: 100px;
    border: 2px solid;
    transform-origin: center 100%;
    transition: all ease 0.5s;
}

.circle-center__hand--origin {
    border-color: #38bdf8;
}

.circle-center__hand--target {
    border-color: #fca5a5;
}
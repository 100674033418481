.nav-dropdown-container:hover .nav-dropdown-items-container {
    display: flex;
}

.nav-dropdown-container {
    position: relative;
}

.nav-dropdown-items-container {
    position: absolute;
}

.nav-dropdown-container--mobile .nav-dropdown-items-container--active {
    display: flex;
    bottom: 100%;
}
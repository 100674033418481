@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
    min-height: 100vh;
    height: 100%;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: clip;
}

.contents {
    align-items: center;
    flex-direction: column;
    max-width: 1240px;
    margin-top: 20px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    flex-grow: 1;
    width: 100%;
}

.content {
    overflow-x: hidden;
    overflow-y: visible;
}

main {
    overflow: auto;
    position: relative;
    flex-grow: 1;
}

main::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;

    display: block;
    background-image: url('./assets/images/main-background.jpeg');
    background-size: 600px;
    width: 100%;
    height: 100%;

    filter: blur(9px) grayscale(0.7) contrast(0.1) brightness(1.8);
}
.grid-buttons-container {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    column-gap: 0.6rem;
    row-gap: 0.6rem;
}

.grid-buttons-container--mobile {
    grid-template-columns: repeat(auto-fill, minmax(65px, 1fr));
    column-gap: 0.4rem;
    row-gap: 0.4rem;
}

.grid-buttons-container--modes {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
}

.grid-buttons-container--intervals {
    grid-template-columns: repeat(auto-fill, minmax(157px, 1fr));
}